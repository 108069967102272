const landingImageArray = [
    'https://i.imgur.com/lAI7WBI.png',
    'https://i.imgur.com/K11RDZr.png',
    'https://i.imgur.com/zfEEW2r.png',
    'https://i.imgur.com/now5biz.png',
    'https://i.imgur.com/ovlfplL.png',
    'https://i.imgur.com/xFbPCiY.png',
    'https://i.imgur.com/QFlgfWZ.png',
    'https://i.imgur.com/9oYrmjx.png',
    'https://i.imgur.com/tPo4j5b.png',
    'https://i.imgur.com/xcoJYr3.png',
    'https://i.imgur.com/FTJXBQd.png',
    'https://i.imgur.com/pXgOmQd.png',
    'https://i.imgur.com/yglyoSh.png',
    'https://i.imgur.com/kO5It6A.png'
]

export default landingImageArray